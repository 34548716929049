import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Button, Card, CardContent, Chip, Grid, IconButton, MenuItem, Pagination, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import config from 'src/config';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import showLoading from 'src/common/loading';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { getCompanies, updateCompany } from 'src/services/company.service';
import DoneIcon from '@mui/icons-material/Done';
import AddEditCompanyModal from './AddEditCompany.modal';
import moment from 'moment';

// ----------------------------------------------------------------------



class CompaniesPage extends React.Component {

	constructor(props) {
		super(props);

		this.state = {

			role: localStorage.getItem('role') || config.USER_ROLES.USER,

			showAddEditCompanyModal: false,
			selectedCompany: null,

			companies: [],
			search: '',
			limit: 10,
			skip: 0,
			total_companies: 0,
		}
	}

	// promised setState
	setStateAsync(state) {
		return new Promise((resolve) => {
			this.setState(state, resolve)
		});
	}

	async componentDidMount() {
		await this.fetchCompanies();
	}

	async fetchCompanies(showLoader = true) {
		try {

			if (showLoader)
				showLoading(true);


			const options = {
				limit: this.state.limit,
				skip: this.state.skip
			}

			if (this.state.search)
				options.search = this.state.search;

			const companies = await getCompanies(options);
			if (companies.error) {

				showLoading(false);

				Swal.fire({
					icon: 'error',
					title: 'Error',
					text: companies.error,
					confirmButtonText: 'Ok',
					confirmButtonColor: config.primaryColor
				});
				return;
			}

			this.setState({
				companies: companies.companies,
				file_containers: companies.containers,
				total_companies: companies.count
			});
			showLoading(false);

		} catch (error) {
			showLoading(false);
			console.error("Error fetching companies:", error.message)
		}
	}

	async activateCompany(company_id) {

		try {
			showLoading(true);

			const response = await updateCompany(company_id, { is_active: true });
			if (response.error) {
				
				showLoading(false);

				Swal.fire({
					icon: 'error',
					title: 'Error',
					text: response.error,
					confirmButtonText: 'Ok',
					confirmButtonColor: config.primaryColor
				});
				return;
			}

			await this.fetchCompanies(false);
			showLoading(false);

		} catch (error) {
			showLoading(false);
			console.error("Error verifying company:", error.message)
		}
	}

	async deactivateCompany(company_id) {
		
		try {
			showLoading(true);

			const response = await updateCompany(company_id, { is_active: false });
			if (response.error) {
				
				showLoading(false);

				Swal.fire({
					icon: 'error',
					title: 'Error',
					text: response.error,
					confirmButtonText: 'Ok',
					confirmButtonColor: config.primaryColor
				});
				return;
			}

			await this.fetchCompanies(false);
			showLoading(false);

		} catch (error) {
			showLoading(false);
			console.error("Error unverifying company:", error.message)
		}
	}

	async verifyCompany(company_id) {

		try {
			showLoading(true);

			const response = await updateCompany(company_id, { is_verified: true });
			if (response.error) {
				
				showLoading(false);

				Swal.fire({
					icon: 'error',
					title: 'Error',
					text: response.error,
					confirmButtonText: 'Ok',
					confirmButtonColor: config.primaryColor
				});
				return;
			}

			await this.fetchCompanies(false);
			showLoading(false);

		} catch (error) {
			showLoading(false);
			console.error("Error verifying company:", error.message)
		}
	}

	async unverifyCompany(company_id) {
		
		try {
			showLoading(true);

			const response = await updateCompany(company_id, { is_verified: false });
			if (response.error) {
				
				showLoading(false);

				Swal.fire({
					icon: 'error',
					title: 'Error',
					text: response.error,
					confirmButtonText: 'Ok',
					confirmButtonColor: config.primaryColor
				});
				return;
			}

			await this.fetchCompanies(false);
			showLoading(false);

		} catch (error) {
			showLoading(false);
			console.error("Error unverifying company:", error.message)
		}
	}

	async updateCompanyRole(company_id, role) {
		
		try {
			showLoading(true);

			const response = await updateCompany(company_id, { role });
			if (response.error) {
				
				showLoading(false);

				Swal.fire({
					icon: 'error',
					title: 'Error',
					text: response.error,
					confirmButtonText: 'Ok',
					confirmButtonColor: config.primaryColor
				});
				return;
			}

			await this.fetchCompanies(false);
			showLoading(false);

		} catch (error) {
			showLoading(false);
			console.error("Error updating company role:", error.message)
		}
	}


	handleSearch = async (e) => {

		console.log("searching...", e.target.value)

		await this.setStateAsync({
			skip: 0,
			search: e.target.value
		})

		await this.fetchCompanies(false);
	}

	render() {
		return (
			<>
				<Helmet>
					<title> Companies | {config.APP_NAME} </title>
				</Helmet>

				<Grid container spacing={2} mb={1} sx={{ p: 0, justifyContent: 'space-between' }}>
					<Grid item xs={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
						<Typography variant="h4">
							Companies
						</Typography>
					</Grid>
				</Grid>

				<Card variant='outlined' style={{ marginBottom: 2 }}>
					<CardContent style={{ padding: 10 }}>
						<Grid container spacing={2}>
							<Grid item xs={12} md={12} lg={12}>
								<Stack direction="row" spacing={2} alignItems="center">
									<TextField
										size='small'
										label={'Search'}
										variant="outlined"
										fullWidth
										value={this.state.search}
										InputProps={{ endAdornment: <SearchIcon /> }}
										onChange={this.handleSearch}
									/>
									<IconButton aria-label="clear" size="large"
										onClick={async () => {
											await this.setStateAsync({
												skip: 0,
												search: ''
											})
											await this.fetchCompanies();
										}}
									>
										<ClearIcon />
									</IconButton>
									<Button
										variant="contained"
										color="primary"
										onClick={async () => {
											this.setState({
												selectedCompany: null,
												showAddEditCompanyModal: true
											});
										}}
										style={{marginLeft: 'auto'}}
									>
										+ Add Company
									</Button>
								</Stack>
							</Grid>
						</Grid>

					</CardContent>
				</Card>

				<Grid container>
					<Grid item xs={12} sx={{ minHeight: '80vh', overflow: 'auto' }}>
						<Card sx={{ height: '100%' }} variant='outlined'>
							<CardContent
								style={{ padding: 10 }}
							>
								
								<TableContainer component={Card}>
									<Table sx={{ minWidth: 650 }} aria-label="companies table">
										<TableHead>
											<TableRow>
												<TableCell>Name</TableCell>
												<TableCell>Joined On</TableCell>
												<TableCell align="right">Status</TableCell>
												<TableCell align="right">Verified</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{this.state.companies.map((company) => (
												<TableRow
													key={company._id}
													sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
												>
													<TableCell component="th" scope="row">
														{company.name}
													</TableCell>
													<TableCell component="th" scope="row">
														{(company.created_at) ? (
															moment(company.created_at).format("YYYY-MM-DD HH:mm")
														) : null}
													</TableCell>
													<TableCell align="right">
														{(company.is_active) ? (
															<Chip
																label="Active"
																onClick={() => {
																	this.deactivateCompany(company._id);
																}}
																icon={<DoneIcon />}
																color='success'
															/>
														) : (
															<Chip
																label="Inactive"
																onClick={() => {
																	this.activateCompany(company._id);
																}}
																icon={<ClearIcon />}
																color='error'
															/>
														)}
													</TableCell>
													<TableCell align="right">
														{(company.is_verified) ? (
															<Chip
																label="Verified"
																onClick={() => {
																	this.unverifyCompany(company._id);
																}}
																icon={<DoneIcon />}
																color='success'
															/>
														) : (
															<Chip
																label="Unverified"
																onClick={() => {
																	this.verifyCompany(company._id);
																}}
																icon={<ClearIcon />}
																color='error'
															/>
														)}
													</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</TableContainer>

								<Stack spacing={2} sx={{ alignItems: 'center' }}>
									<Pagination
										page={(this.state.skip / this.state.limit) + 1}
										count={Math.ceil(this.state.total_companies / this.state.limit)}
										shape="rounded"
										size="large"
										onChange={async (event, page) => {
											await this.setStateAsync({
												skip: (page - 1) * this.state.limit
											});
											await this.fetchCompanies();
										}}
										showFirstButton={true}
										showLastButton={true}
										boundaryCount={2}
										style={{ margin: 20 }}
									/>
								</Stack>

							</CardContent>
						</Card>



					</Grid>
				</Grid>


				{/* Add Edit Company Modal */}
				{(this.state.showAddEditCompanyModal) ? (
					<AddEditCompanyModal
						open={this.state.showAddEditCompanyModal}
						onClose={() => {
							this.setState({
								showAddEditCompanyModal: false
							});
						}}
						company={this.state.selectedCompany}
						onSave={async () => {
							await this.setStateAsync({
								showAddEditCompanyModal: false
							});
							await this.fetchCompanies();
						}}
					/>
				) : null}

			</>
		);
	}
}

export default function (props) {
	const navigate = useNavigate();

	return <CompaniesPage {...props} navigate={navigate} />;
}
