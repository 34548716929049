import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Button, Card, CardContent, Grid, Typography } from '@mui/material';
import config from 'src/config';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import DropboxStatusCheckAndConnect from 'src/components/cloud_services/dropbox/DropboxStatusCheckAndConnect';
import OnedriveStatusCheckAndConnect from 'src/components/cloud_services/onedrive/OnedriveStatusCheckAndConnect';
import GoogleDriveStatusCheckAndConnect from 'src/components/cloud_services/googledrive/GoogleDriveStatusCheckAndConnect';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { getStorages } from 'src/services/storage.service';
import showLoading from 'src/common/loading';

// ----------------------------------------------------------------------

class StoragePage extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			storages: [],
			search: '',
			limit: 10,
			skip: 0,
			total_storages: 0,
		}

	}

	async componentDidMount() {
		await this.fetchStorages();
	}

	async fetchStorages(showLoader = true) {
		try {

			if (showLoader)
				showLoading(true);


			const options = {
				limit: this.state.limit,
				skip: this.state.skip
			}

			if (this.state.search)
				options.search = this.state.search;

			const storages = await getStorages(options);
			if (storages.error) {

				showLoading(false);

				Swal.fire({
					icon: 'error',
					title: 'Error',
					text: storages.error,
					confirmButtonText: 'Ok',
					confirmButtonColor: config.primaryColor
				});
				return;
			}

			this.setState({
				storages: storages.storages,
				total_storages: storages.count
			});

			showLoading(false);

		} catch (error) {
			showLoading(false);
			console.error("Error fetching storages:", error.message)
		}
	}

	render() {

		return (
			<>
				<Helmet>
					<title> Storage | {config.APP_NAME} </title>
				</Helmet>

				<Grid container spacing={2} mb={1} sx={{ p: 0, justifyContent: 'space-between' }}>
					<Grid item xs={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
						<Typography variant="h4">
							Storages
						</Typography>
					</Grid>
				</Grid>

				<Grid container>
					{(this.state.storages.map((storage, index) => (
						<Grid item xs={12} key={String(storage._id)}>
							<Card variant='outlined' sx={{ mb: 1 }}>
								<CardContent style={{ padding: 20 }}>
									{(storage.type == config.STORAGE_SERVICES.DROPBOX) ? (
										<>
											<Typography variant="h4">
												Dropbox
											</Typography>
											<DropboxStatusCheckAndConnect
												storageId={storage._id}
												callbackUrl={process.env.REACT_APP_DROPBOX_CALLBACK_URL}
											/>
										</>
									) : null}

									{(storage.type == config.STORAGE_SERVICES.ONEDRIVE) ? (
										<>
											<Typography variant="h4">
												Onedrive
											</Typography>
											<OnedriveStatusCheckAndConnect
												storageId={storage._id}
												callbackUrl={process.env.REACT_APP_ONEDRIVE_CALLBACK_URL}
											/>
										</>	
									) : null}

									{(storage.type == config.STORAGE_SERVICES.GOOGLEDRIVE) ? (
										<>
											<Typography variant="h4">
												Google Drive
											</Typography>
											<GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLEDRIVE_CLIENT_ID}>
												<GoogleDriveStatusCheckAndConnect
													storageId={storage._id}
													callbackUrl={process.env.REACT_APP_GOOGLEDRIVE_CALLBACK_URL}
												/>
											</GoogleOAuthProvider>
										</>	
									) : null}

								</CardContent>
							</Card>
						</Grid>
					)))}

					<Grid item xs={12}>
						<Card variant='outlined' sx={{ mb: 1 }}>
							<CardContent style={{ padding: 20 }}>
								<Typography variant="h4">
									Dropbox
								</Typography>
								<DropboxStatusCheckAndConnect
									hideUnsyncButton={true}
									callbackUrl={process.env.REACT_APP_DROPBOX_CALLBACK_URL}
								/>
							</CardContent>
						</Card>
					</Grid>
					
					<Grid item xs={12}>
						<Card variant='outlined' sx={{ mb: 1 }}>
							<CardContent style={{ padding: 20 }}>
								<Typography variant="h4">
									Google Drive
								</Typography>
								<GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLEDRIVE_CLIENT_ID}>
									<GoogleDriveStatusCheckAndConnect
										hideUnsyncButton={true}
										callbackUrl={process.env.REACT_APP_GOOGLEDRIVE_CALLBACK_URL}
									/>
								</GoogleOAuthProvider>
							</CardContent>
						</Card>
					</Grid>
					
					<Grid item xs={12}>
						<Card variant='outlined' sx={{ mb: 1 }}>
							<CardContent style={{ padding: 20 }}>
								<Typography variant="h4">
									Onedrive
								</Typography>
								<OnedriveStatusCheckAndConnect
									hideUnsyncButton={true}
									callbackUrl={process.env.REACT_APP_ONEDRIVE_CALLBACK_URL}
								/>
							</CardContent>
						</Card>
					</Grid>

				</Grid>

			</>
		);
	}
}

export default function (props) {
	const navigate = useNavigate();

	return <StoragePage {...props} navigate={navigate} />;
}
