// component
import SvgColor from '../../../components/svg-color';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import DifferenceIcon from '@mui/icons-material/Difference';
import StorageIcon from '@mui/icons-material/Storage';
import UsersIcon from '@mui/icons-material/People';
import BusinessIcon from '@mui/icons-material/Business';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import config from 'src/config';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const role = localStorage.getItem('role') || 'user';

let allowedNavs = [];



if (role === config.USER_ROLES.SUPERADMIN) {
  allowedNavs = [
    'dashboard',
    'files',
    'declutter',
    'storage',
    'companies',
    'users',
    'containers'
  ];
} else if (role === config.USER_ROLES.ADMIN) {
  allowedNavs = [
    'dashboard',
    'files',
    'declutter',
    'storage',
    'users',
    'containers'
  ];
} else {
  allowedNavs = [
    'dashboard',
    'files',
    'declutter',
    'storage',
    'containers'
  ];
}

const allNavConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },
  {
    title: 'files',
    path: '/files/view',
    icon: <DocumentScannerIcon />,
  },
  {
    title: 'declutter',
    path: '/declutter/view',
    icon: <DifferenceIcon />,
  },
  {
    title: 'storage',
    path: '/storage/app',
    icon: <StorageIcon />,
  },
  {
    title: 'companies',
    path: '/companies/view',
    icon: <BusinessIcon />,
  },
  {
    title: 'users',
    path: '/users/view',
    icon: <UsersIcon />,
  },
  {
    title: 'containers',
    path: '/containers/view',
    icon: <AllInboxIcon />,
  }
];

const navConfig = allNavConfig.filter((nav) => allowedNavs.includes(nav.title));

export default navConfig;
