import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import LoginPage from './pages/Login.page';
import Page404 from './pages/Page404';
import DashboardAppPage from './pages/DashboardApp.page';
import RegisterPage from './pages/Register.page';
import FilesPage from './pages/Files.page';
import AddEditFilePage from './pages/AddEditFile.page';
import VerifyPage from './pages/Verify.page';
import StoragePage from './pages/Storage.page';
import UsersPage from './pages/Users.page';
import ForgotPasswordPage from './pages/ForgotPassword.page';
import ResetPasswordPage from './pages/ResetPassword.page';
import DeclutterPage from './pages/Declutter.page';
import CompaniesPage from './pages/Companies.page';
import UserInvitePage from './pages/UserInvite.page';
import ContainersPage from './pages/Containers.page';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> }
      ],
    },
    {
      path: '/files',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/files/view" />, index: true },
        { path: 'view', element: <FilesPage /> },
        { path: 'add', element: <AddEditFilePage /> },
        { path: ':file_id', element: <AddEditFilePage /> },
      ]
    },
    {
      path: '/declutter',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/declutter/view" />, index: true },
        { path: 'view', element: <DeclutterPage /> },
      ]
    },
    {
      path: '/storage',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/storage/app" />, index: true },
        { path: 'app', element: <StoragePage /> }
      ],
    },
    {
      path: '/companies',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/companies/view" />, index: true },
        { path: 'view', element: <CompaniesPage /> }
      ],
    },
    {
      path: '/users',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/users/view" />, index: true },
        { path: 'view', element: <UsersPage /> },
        { path: 'invite', element: <UserInvitePage /> },
      ],
    },
    {
      path: '/containers',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/containers/view" />, index: true },
        { path: 'view', element: <ContainersPage /> }
      ]
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'register',
      element: <RegisterPage />,
    },
    {
      path: 'forgotpassword',
      element: <ForgotPasswordPage />,
    },
    {
      path: 'resetpassword',
      element: <ResetPasswordPage />,
    },
    {
      path: '/verify',
      element: <VerifyPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/login" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
