import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Button, Card, CardContent, Chip, Grid, IconButton, MenuItem, Pagination, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import config from 'src/config';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import showLoading from 'src/common/loading';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { getUsers, updateUser } from 'src/services/user.service';
import DoneIcon from '@mui/icons-material/Done';
import { getCompanies } from 'src/services/company.service';
import moment from 'moment';

// ----------------------------------------------------------------------



class UsersPage extends React.Component {

	constructor(props) {
		super(props);

		this.state = {

			role: localStorage.getItem('role') || config.USER_ROLES.USER,

			companies: [],

			users: [],
			search: '',
			limit: 10,
			skip: 0,
			total_users: 0,
		}
	}

	// promised setState
	setStateAsync(state) {
		return new Promise((resolve) => {
			this.setState(state, resolve)
		});
	}

	async componentDidMount() {
		this.fetchCompanies();
		await this.fetchUsers();
	}

	async fetchCompanies() {
		try {
			
			const companies = await getCompanies({minimal: true});
			if (companies.error) {

				Swal.fire({
					icon: 'error',
					title: 'Error',
					text: companies.error,
					confirmButtonText: 'Ok',
					confirmButtonColor: config.primaryColor
				});
				return;
			}

			this.setState({
				companies: companies.companies
			});

		} catch (error) {
			console.error("Error fetching companies:", error.message)
		}
	}

	async fetchUsers(showLoader = true) {
		try {

			if (showLoader)
				showLoading(true);


			const options = {
				limit: this.state.limit,
				skip: this.state.skip
			}

			if (this.state.search)
				options.search = this.state.search;

			const users = await getUsers(options);
			if (users.error) {

				showLoading(false);

				Swal.fire({
					icon: 'error',
					title: 'Error',
					text: users.error,
					confirmButtonText: 'Ok',
					confirmButtonColor: config.primaryColor
				});
				return;
			}

			this.setState({
				users: users.users,
				file_containers: users.containers,
				total_users: users.count
			});
			showLoading(false);

		} catch (error) {
			showLoading(false);
			console.error("Error fetching users:", error.message)
		}
	}

	async verifyUser(user_id) {

		try {
			showLoading(true);

			const response = await updateUser(user_id, { is_verified: true });
			if (response.error) {
				
				showLoading(false);

				Swal.fire({
					icon: 'error',
					title: 'Error',
					text: response.error,
					confirmButtonText: 'Ok',
					confirmButtonColor: config.primaryColor
				});
				return;
			}

			await this.fetchUsers(false);
			showLoading(false);

		} catch (error) {
			showLoading(false);
			console.error("Error verifying user:", error.message)
		}
	}

	async unverifyUser(user_id) {
		
		try {
			showLoading(true);

			const response = await updateUser(user_id, { is_verified: false });
			if (response.error) {
				
				showLoading(false);

				Swal.fire({
					icon: 'error',
					title: 'Error',
					text: response.error,
					confirmButtonText: 'Ok',
					confirmButtonColor: config.primaryColor
				});
				return;
			}

			await this.fetchUsers(false);
			showLoading(false);

		} catch (error) {
			showLoading(false);
			console.error("Error unverifying user:", error.message)
		}
	}

	async updateUserRole(user_id, role) {
		
		try {
			showLoading(true);

			const response = await updateUser(user_id, { role });
			if (response.error) {
				
				showLoading(false);

				Swal.fire({
					icon: 'error',
					title: 'Error',
					text: response.error,
					confirmButtonText: 'Ok',
					confirmButtonColor: config.primaryColor
				});
				return;
			}

			await this.fetchUsers(false);
			showLoading(false);

		} catch (error) {
			showLoading(false);
			console.error("Error updating user role:", error.message)
		}
	}

	async updateUserAccountType(user_id, account_type) {

		try {
			showLoading(true);

			let payload = { account_type };
			if (account_type === config.USER_ACCOUNT_TYPES.PERSONAL) {
				payload.company = null;
				payload.role = config.USER_ROLES.USER;
			}

			const response = await updateUser(user_id, payload);
			if (response.error) {

				showLoading(false);

				Swal.fire({
					icon: 'error',
					title: 'Error',
					text: response.error,
					confirmButtonText: 'Ok',
					confirmButtonColor: config.primaryColor
				});
				return;
			}

			await this.fetchUsers(false);
			showLoading(false);

		} catch (error) {
			showLoading(false);
			console.error("Error updating user account type:", error.message)
		}
	}

	async updateUserCompany(user_id, company_id) {

		try {
			showLoading(true);

			const response = await updateUser(user_id, { company: company_id });
			if (response.error) {

				showLoading(false);

				Swal.fire({
					icon: 'error',
					title: 'Error',
					text: response.error,
					confirmButtonText: 'Ok',
					confirmButtonColor: config.primaryColor
				});
				return;
			}

			await this.fetchUsers(false);
			showLoading(false);

		} catch (error) {
			showLoading(false);
			console.error("Error updating user company:", error.message)
		}
	}


	handleSearch = async (e) => {

		console.log("searching...", e.target.value)

		await this.setStateAsync({
			skip: 0,
			search: e.target.value
		})

		await this.fetchUsers(false);
	}

	render() {
		return (
			<>
				<Helmet>
					<title> Users | {config.APP_NAME} </title>
				</Helmet>

				<Grid container spacing={2} mb={1} sx={{ p: 0, justifyContent: 'space-between' }}>
					<Grid item xs={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
						<Typography variant="h4">
							Users
						</Typography>
					</Grid>
				</Grid>

				<Card variant='outlined' style={{ marginBottom: 2 }}>
					<CardContent style={{ padding: 10 }}>
						<Grid container spacing={2}>
							<Grid item xs={12} md={12} lg={12}>
								<Stack direction="row" spacing={2} alignItems="center">
									<TextField
										size='small'
										label={'Search'}
										variant="outlined"
										fullWidth
										value={this.state.search}
										InputProps={{ endAdornment: <SearchIcon /> }}
										onChange={this.handleSearch}
									/>
									<IconButton aria-label="clear" size="large"
										onClick={async () => {
											await this.setStateAsync({
												skip: 0,
												search: ''
											})
											await this.fetchUsers();
										}}
									>
										<ClearIcon />
									</IconButton>
									<Button
										variant="contained"
										color="primary"
										onClick={async () => {
											this.props.navigate('/users/invite');
										}}
									>
										+ Invite User
									</Button>
								</Stack>
							</Grid>
						</Grid>

					</CardContent>
				</Card>

				<Grid container>
					<Grid item xs={12} sx={{ minHeight: '80vh', overflow: 'auto' }}>
						<Card sx={{ height: '100%' }} variant='outlined'>
							<CardContent
								style={{ padding: 10 }}
							>
								
								<TableContainer component={Card}>
									<Table sx={{ minWidth: 650 }} aria-label="users table">
										<TableHead>
											<TableRow>
												<TableCell>Username / Email</TableCell>
												<TableCell>Account type</TableCell>
												<TableCell>Company</TableCell>
												<TableCell>Role</TableCell>
												<TableCell>Joined On</TableCell>
												<TableCell>Last login</TableCell>
												<TableCell align="right">Verified</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{this.state.users.map((user) => (
												<TableRow
													key={user._id}
													sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
												>
													<TableCell component="th" scope="row">
														{user.username}
													</TableCell>
													<TableCell>
														<Select
															labelId="account-type-select-label"
															id="account-type-select"
															value={user.account_type}
															label="Account Type"
															onChange={async (e) => {
																await this.updateUserAccountType(user._id, e.target.value);
															}}
														>

															{(this.state.role === config.USER_ROLES.SUPERADMIN) ? (
																<MenuItem value={config.USER_ACCOUNT_TYPES.PERSONAL}>Personal</MenuItem>
															) : null}
															<MenuItem value={config.USER_ACCOUNT_TYPES.BUSINESS}>Business</MenuItem>
														</Select>
													</TableCell>
													<TableCell>
														{(user.account_type == config.USER_ACCOUNT_TYPES.BUSINESS) ? (
															<Select
																labelId="company-select-label"
																id="company-select"
																value={user.company}
																label="Company"
																onChange={async (e) => {
																	await this.updateUserCompany(user._id, e.target.value);
																}}
															>
																{(this.state.companies.map((company) => (
																	<MenuItem value={company._id}>{company.name}</MenuItem>
																)))}
															</Select>
														) : null}
													</TableCell>
													<TableCell>
														{(user.account_type == config.USER_ACCOUNT_TYPES.BUSINESS) ? (
															<Select
																labelId="role-select-label"
																id="role-select"
																value={user.role}
																label="Role"
																onChange={async (e) => {
																	await this.updateUserRole(user._id, e.target.value);
																}}
															>

																{(this.state.role === config.USER_ROLES.SUPERADMIN) ? (
																	<MenuItem value={config.USER_ROLES.SUPERADMIN}>Superadmin</MenuItem>
																) : null}
																{(this.state.role === config.USER_ROLES.ADMIN || this.state.role === config.USER_ROLES.SUPERADMIN) ? (
																	<MenuItem value={config.USER_ROLES.ADMIN}>Admin</MenuItem>
																) : null}
																{(this.state.role === config.USER_ROLES.MANAGER || this.state.role === config.USER_ROLES.ADMIN || this.state.role === config.USER_ROLES.SUPERADMIN) ? (
																	<MenuItem value={config.USER_ROLES.MANAGER}>Manager</MenuItem>
																) : null}
																<MenuItem value={config.USER_ROLES.USER}>User</MenuItem>
															</Select>
														) : null}
													</TableCell>

													<TableCell component="th" scope="row">
														{moment(user.created_at).format("YYYY-MM-DD HH:mm")}
													</TableCell>
													
													<TableCell component="th" scope="row">
														{(user.last_login) ? (
															moment(user.last_login).format("YYYY-MM-DD HH:mm")
														) : null}
													</TableCell>
													
													
													<TableCell align="right">
														{(user.is_verified) ? (
															<Chip
																label="Verified"
																onClick={() => {
																	this.unverifyUser(user._id);
																}}
																icon={<DoneIcon />}
																color='success'
															/>
														) : (
															<Chip
																label="Not Verified"
																onClick={() => {
																	this.verifyUser(user._id);
																}}
																icon={<ClearIcon />}
																color='error'
															/>
														)}
													</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</TableContainer>

								<Stack spacing={2} sx={{ alignItems: 'center' }}>
									<Pagination
										page={(this.state.skip / this.state.limit) + 1}
										count={Math.ceil(this.state.total_users / this.state.limit)}
										shape="rounded"
										size="large"
										onChange={async (event, page) => {
											await this.setStateAsync({
												skip: (page - 1) * this.state.limit
											});
											await this.fetchUsers();
										}}
										showFirstButton={true}
										showLastButton={true}
										boundaryCount={2}
										style={{ margin: 20 }}
									/>
								</Stack>

							</CardContent>
						</Card>



					</Grid>
				</Grid>

			</>
		);
	}
}

export default function (props) {
	const navigate = useNavigate();

	return <UsersPage {...props} navigate={navigate} />;
}
