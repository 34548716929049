import React from 'react';
import { Autocomplete, Button, Card, CardContent, Grid, Modal, Stack, TextField } from '@mui/material';
import config from 'src/config';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import showLoading from 'src/common/loading';
import { addContainer, updateContainer } from 'src/services/container.service';
import PersonIcon from '@mui/icons-material/Person';

// ----------------------------------------------------------------------



class AddEditContainerModal extends React.Component {

	constructor(props) {
		super(props);

		this.state = {

			role: localStorage.getItem('role') || config.USER_ROLES.USER,
			mode: 'add',

			container: null,
			name: '',
			shared_users: [],

		}
	}

	// promised setState
	setStateAsync(state) {
		return new Promise((resolve) => {
			this.setState(state, resolve)
		});
	}

	async componentDidMount() {
		if (this.props.container) {
			await this.setStateAsync({
				mode: 'edit',
				container: this.props.container,
				name: this.props.container.name,
				shared_users: this.props.container.shared_users
			});
		}
	}

	isFormValid() {
		if (!this.state.name || this.state.name.trim() === '') {
			Swal.fire({
				icon: 'error',
				title: 'Error',
				text: 'Name is required'
			});
			return false;
		}
		return true;
	}

	async addContainer() {

		if (!this.isFormValid())
			return;

		showLoading(true);
		let addContainerRes = await addContainer({
			name: this.state.name,
			shared_users: this.state.shared_users
		});
		if (addContainerRes.error) {
			Swal.fire({
				icon: 'error',
				title: 'Error',
				text: addContainerRes.error
			});
		} else {
			Swal.fire({
				icon: 'success',
				title: 'Success',
				text: 'Container added successfully'
			});
			this.setState({
				name: ''
			});
			if (this.props.onSave)
				this.props.onSave();
		}
	}

	async editContainer() {
		if (!this.isFormValid())
			return;

		showLoading(true);
		let addContainerRes = await updateContainer(this.props.container._id, {
			name: this.state.name,
			shared_users: this.state.shared_users
		});
		if (addContainerRes.error) {
			Swal.fire({
				icon: 'error',
				title: 'Error',
				text: addContainerRes.error
			});
		} else {
			Swal.fire({
				icon: 'success',
				title: 'Success',
				text: 'Container updated successfully'
			});
			this.setState({
				name: ''
			});
			if (this.props.onSave)
				this.props.onSave();
		}
	}

	async saveContainer() {
		if (this.state.container) {
			await this.editContainer();
		} else {
			await this.addContainer();
		}
	}

	render() {
		return (
			<Modal
				open={this.props.open}
				onClose={() => {

					if (this.props.onClose)
						this.props.onClose();
				}}
			>
				<Grid container>
					<Grid item style={modalStyle} xs={12} md={8} lg={6}>
						<Card variant='outlined'>
							<CardContent style={{ padding: 20 }}>
								<Stack direction="column" spacing={2}>
									<TextField
										size='small'
										label={'Name'}
										variant="outlined"
										fullWidth
										value={this.state.name}
										onChange={async (e) => {
											await this.setStateAsync({
												name: e.target.value
											});
										}}
										disabled={this.state.mode == 'edit' && !this.state?.container?.is_owner}
									/>
									<Autocomplete
										freeSolo
										multiple
										id="shared-users-outlined"
										options={this.state.shared_users}
										value={this.state.shared_users}
										onChange={(event, newValue) => {
											this.setState({
												shared_users: newValue
											});
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												variant="outlined"
												label="Shared with"
												placeholder={((this.state.mode == 'add') || (this.state.mode == 'edit' && this.state?.container?.is_owner)) ? "Add user emails ( dMonk registered users only )" : ""}
												InputProps={{
													...params.InputProps,
													endAdornment: (
														<React.Fragment>
															{params.InputProps.endAdornment}
															<PersonIcon />
														</React.Fragment>
													)
												}}
											/>
										)}
										sx={{ width: '100%' }}
										size="small"
										disabled={this.state.mode == 'edit' && !this.state?.container?.is_owner}
									/>
									{((this.state.mode == 'add') || (this.state.mode == 'edit' && this.state?.container?.is_owner)) && (
										<Button
											variant='contained'
											fullWidth
											color='primary'
											onClick={async () => {
												this.saveContainer();
											}}
										>
											Save
										</Button>
									)}
								</Stack>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</Modal>
		);
	}
}

const modalStyle = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '100%',
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 2,
	maxHeight: '90%',
	overflowY: 'auto'
};

export default function (props) {
	const navigate = useNavigate();

	return <AddEditContainerModal {...props} navigate={navigate} />;
}
