import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Autocomplete, Button, Card, CardContent, Checkbox, Chip, FormControlLabel, FormGroup, Grid, IconButton, MenuItem, Pagination, Stack, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import config from 'src/config';
import SearchIcon from '@mui/icons-material/Search';
import LabelIcon from '@mui/icons-material/Label';
import Swal from 'sweetalert2';
import showLoading from './../common/loading';
import { deleteMultipleFiles, getFiles } from '../services/file.service';
import { useNavigate, useParams } from 'react-router-dom';
import FileCard from 'src/components/file-card';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ClearIcon from '@mui/icons-material/Clear';
import { ReactWebStore } from 'react-webstore';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import DropboxStatusCheckAndConnect from 'src/components/cloud_services/dropbox/DropboxStatusCheckAndConnect';
import OnedriveStatusCheckAndConnect from 'src/components/cloud_services/onedrive/OnedriveStatusCheckAndConnect';
import GoogleDriveStatusCheckAndConnect from 'src/components/cloud_services/googledrive/GoogleDriveStatusCheckAndConnect';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { getAvailableStorageServices, getStorages } from 'src/services/storage.service';
import { fData } from 'src/utils/formatNumber';
import AddIcon from '@mui/icons-material/Add';
import AddLabelOrFlagModal from 'src/modals/AddLabelOrFlag.modal';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import StorageIcon from '@mui/icons-material/Storage';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import OnedriveLogo from 'src/assets/storageIcons/onedrive.png';
import GoogleDriveLogo from 'src/assets/storageIcons/googledrive.png';
import DropboxLogo from 'src/assets/storageIcons/dropbox.png';
import ShareIcon from '@mui/icons-material/Share';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import EmailIcon from '@mui/icons-material/Email';
import { getSearchSuggestions } from 'src/services/search.service';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import InboxIcon from '@mui/icons-material/Inbox';
import AddEditContainerModal from './AddEditContainer.modal';
import AddContainerModal from 'src/modals/AddContainer.modal';

const WordImg = require('./../assets/fileIcons/word.png');
const ExcelImg = require('./../assets/fileIcons/excel.png');
const PowerpointImg = require('./../assets/fileIcons/powerpoint.png');
const PdfImg = require('./../assets/fileIcons/pdf.png');
const ImageImg = require('./../assets/fileIcons/image.png');
const AudioImg = require('./../assets/fileIcons/audio.png');
const VideoImg = require('./../assets/fileIcons/video.png');
const ArchiveImg = require('./../assets/fileIcons/archive.png');
const CadImg = require('./../assets/fileIcons/cad.png');
const DesignImg = require('./../assets/fileIcons/design.png');
const NoteImg = require('./../assets/fileIcons/note.png');
const TextImg = require('./../assets/fileIcons/text.png');
const CodeImg = require('./../assets/fileIcons/code.png');
const FileImg = require('./../assets/fileIcons/file.png');


// ----------------------------------------------------------------------

const fileIcon = {
	'word': WordImg,
	'excel': ExcelImg,
	'powerpoint': PowerpointImg,
	'pdf': PdfImg,
	'image': ImageImg,
	'audio': AudioImg,
	'video': VideoImg,
	'archive': ArchiveImg,
    'cad': CadImg,
    'design': DesignImg,
    'note': NoteImg,
    'text': TextImg,
    'code': CodeImg,
	'file': FileImg
}

const flags = [
	{ value: 'attachment', label: 'Attachment', color: 'purple' },
	{ value: 'important', label: 'Important', color: 'red' },
	{ value: 'in_house', label: 'In house', color: 'blue' },
	{ value: 'temp', label: 'Temp', color: 'black' },
	{ value: 'wip', label: 'WIP', color: 'orange' },
	{ value: 'complete', label: 'Complete', color: 'green' },
	{ value: 'final', label: 'Final', color: 'red'},
	{ value: 'archive', label: 'Archive', color: 'grey'},
]

// Debounce function
function debounce(func, wait) {
    let timeout;
    return function(...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, args), wait);
    };
}

class Files extends React.Component {
  

	constructor(props) {
		super(props);

        this.rws = new ReactWebStore();

		this.state = {

            available_storages: [],
            
            files: [],
            search: '',
            search_suggestions: [],
            mini_loading: false,
            limit: 12,
            skip: 0,
            total_files: 0,
            total_size: 0,
            flags: [],
            labels: [],
            containers: [],
            persons: [],
            emails: [],
            file_ids: [],
            shared_by_me: false,
            shared_with_me: false,
            flagged: false,
            labelled: false,
            unorganized: true,
            organized: false,
            recent: true,
            recent_type: 'last_week',
            service: [],
            extension: [],
            advanced_search: false,
            show_selection: false,
            selected: {
                files: [],
                total_size: 0
            },
            select_all: false,
            addLabelModal: false,
            addFlagModal: false,
            addContainerModal: false,
            showAddEditContainerModal: false,
        };

        this.fetchFiles = debounce(this.fetchFiles, 100);
	}


	// promised setState
	setStateAsync(state) {
		return new Promise((resolve) => {
			this.setState(state, resolve)
		});
	}

    async componentDidMount() {
        await this.fetchFiles();

        this.rws.subscribe('sync_files', async (e) => {
            if (this.rws.getStore(e.type) == 'success') {
                this.fetchFiles(false);
            }
        })

        let available_storages = await getStorages();
        if (!available_storages.error) {
            this.setState({
                available_storages: available_storages.storages
            })
        }
    }

    getQuery(all_files = false) {
        const options = {
            limit: this.state.limit,
            skip: this.state.skip
        }

        if (this.state.search)
            options.search = this.state.search;

        if (this.state.flags && this.state.flags.length > 0)
            options.flags = this.state.flags;

        if (this.state.labels && this.state.labels.length > 0)
            options.labels = this.state.labels.map(l => l.value);
        
        if (this.state.containers && this.state.containers.length > 0)
            options.containers = this.state.containers.map(l => l.value);

        if (this.state.containers && this.state.containers.length > 0)
            options.containers = this.state.containers.map(c => c.value);

        if (this.state.containers && this.state.containers.length > 0)
            options.containers = this.state.containers.map(c => c.value);

        if (this.state.persons && this.state.persons.length > 0)
            options.persons = this.state.persons.map(p => p.value);

        if (this.state.emails && this.state.emails.length > 0)
            options.emails = this.state.emails.map(e => e.value);

        if (this.state.recent)
            options.recent = this.state.recent_type;

        if (this.state.shared_by_me)
            options.shared_by_me = 'true';

        if (this.state.shared_with_me)
            options.shared_with_me = 'true';

        if (this.state.flagged)
            options.flagged = 'true';
        
        if (this.state.labelled)
            options.labelled = 'true';
        
        if (this.state.unorganized)
            options.unorganized = 'true';

        if (this.state.organized)
            options.organized = 'true';

        if (this.state.service && this.state.service.length > 0)
            options.service = this.state.service.join(',');

        if (this.state.extension && this.state.extension.length > 0)
            options.extension = this.state.extension.join(',');

        if (this.state.file_ids && this.state.file_ids.length > 0)
            options.file_ids = this.state.file_ids.join(',');

        if (this.state.sort_key && this.state.sort_order) {
            options.sort_key = this.state.sort_key;
            options.sort_order = this.state.sort_order;
        }

        if (all_files) {
            delete options.limit;
            delete options.skip;
            delete options.file_ids;
        }

        return options;

    }

    fetchSearchSuggestions = async () => {
        try {
            const options = {
                search: this.state.search,
                labels: this.state.labels.map(l => l.value),
                containers: this.state.containers.map(l => l.value),
                persons: this.state.persons.map(p => p.value),
                emails: this.state.emails.map(e => e.value)
            }
            const search_suggestions = await getSearchSuggestions(options);
            if (!search_suggestions || search_suggestions?.error) {
                this.setState({
                    search_suggestions: []
                });
                return;
            }

            this.setState({
                search_suggestions: search_suggestions.suggestions
            });
        } catch (error) {
            console.error("Error fetching search suggestions:", error.message)
        }
    }

    async fetchFiles(showLoader = true) {
        try {

            if (showLoader)
                showLoading(true);
            this.setState({
                mini_loading: true
            });


            const options = this.getQuery();

            const files = await getFiles(options);
            if (!files) {
                showLoading(false);
                this.setState({
                    mini_loading: false
                });
                return;
            }
            if (files.error) {

                showLoading(false);
                this.setState({
                    mini_loading: false
                });
                

                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: files.error,
                    confirmButtonText: 'Ok',
                    confirmButtonColor: config.primaryColor
                });
                return;
            }
            
            this.setState({
                files: files.files,
                total_files: files.count,
                total_size: files.total_size,
                mini_loading: false
            });
            showLoading(false);

            this.fetchSearchSuggestions();

        } catch (error) {
            showLoading(false);
            console.error("Error fetching files:", error.message)
        }
    }

    handleSearch = async (e) => {
        await this.setStateAsync({
            skip:0,
            search: e.target.value
        })

        // get search suggestions
        this.fetchSearchSuggestions();

        if (this.state.search.length > 0 && !this.state.advanced_search) {
            await this.setStateAsync({
                recent: false,
                recent_type: '',
                unorganized: false
            })
        } else if (this.state.search.length === 0 && !this.state.advanced_search && (this.state.labels.length > 0 || this.state.persons.length > 0 || this.state.emails.length > 0 || this.state.containers.length)) {
            await this.setStateAsync({
                recent: false,
                recent_type: '',
                unorganized: false
            })
        } else if (this.state.search.length === 0 && this.state.file_ids.length) {
            await this.setStateAsync({
                recent: false,
                recent_type: '',
                unorganized: false
            })
        } else if (this.state.search.length === 0 && !this.state.advanced_search) {
            await this.setStateAsync({
                recent: true,
                recent_type: 'last_week',
                unorganized: true
            })
        }

        await this.fetchFiles(false);
    }

    handleFlagsSearch = async () => {
        await this.setStateAsync({
            skip:0
        })
        await this.fetchFiles(false);
    }

    handleSharedByMe = async (e) => {
        await this.setStateAsync({
            skip:0,
            shared_by_me: e.target.checked,
            shared_with_me: false
        })
        await this.fetchFiles(false);
    }

    handleSharedWithMe = async (e) => {
        await this.setStateAsync({
            skip:0,
            shared_with_me: e.target.checked,
            shared_by_me: false
        })
        await this.fetchFiles(false);
    }

    handleFlagged = async (e) => {
        await this.setStateAsync({
            skip:0,
            flagged: e.target.checked
        })
        await this.fetchFiles(false);
    }

    handleLabelled = async (e) => {
        await this.setStateAsync({
            skip:0,
            labelled: e.target.checked
        })
        await this.fetchFiles(false);
    }

    handleUnorganized = async (e) => {
        let unorganized = e.target.checked;
        let organized = this.state.organized;
        if (unorganized) {
            organized = false;
        }

        await this.setStateAsync({
            skip:0,
            unorganized,
            organized
        })
        await this.fetchFiles(false);
    }

    handleOrganized = async (e) => {
        let organized = e.target.checked;
        let unorganized = this.state.unorganized;
        if (organized) {
            unorganized = false;
        }

        await this.setStateAsync({
            skip:0,
            organized,
            unorganized
        })
        await this.fetchFiles(false);
    }


    handleRecent = async (e) => {
        await this.setStateAsync({
            skip:0,
            recent: e.target.checked,
            recent_type: (e.target.checked) ? 'last_week' : '',
            age_sort: ''
        })
        await this.fetchFiles(false);
    }

    handleRecentType = (type) => async (e) => {
        await this.setStateAsync({
            skip:0,
            recent: true,
            recent_type: type,
            age_sort: ''
        })
        await this.fetchFiles(false);
    }

    handleAgeSort = async (e, value) => {
        
        let sort_key;
        let sort_order;

        if (value === 'youngest_active') {
            sort_key = 'active_age';
            sort_order = 'DESC';
        } else if (value === 'oldest_active') {
            sort_key = 'active_age';
            sort_order = 'ASC';
        } else if (value === 'youngest_inactive') {
            sort_key = 'inactive_age';
            sort_order = 'ASC';
        } else if (value === 'oldest_inactive') {
            sort_key = 'inactive_age';
            sort_order = 'DESC';
        }

        await this.setStateAsync({
            age_sort: value,
            skip:0,
            recent: false,
            recent_type: '',
            sort_key,
            sort_order,
        })
        await this.fetchFiles(false);
    }

    handleService = (service) => async (e) => {
        let services = this.state.service;
        if (services.includes(service)) {
            services = services.filter(s => s !== service);
        } else {
            services.push(service);
        }
        await this.setStateAsync({
            skip:0,
            service: services
        })
        await this.fetchFiles(false);
    }

    handleExtension = (file_type) => async (e) => {
        let extensions = this.state.extension;
        
        if (extensions.includes(file_type)) {
            extensions = extensions.filter(e => e !== file_type);
        } else {
            extensions.push(file_type);
        }

        await this.setStateAsync({
            skip:0,
            extension: extensions
        })
        await this.fetchFiles(false);
    }

    handleClearSearchAndFilters = async (showLoader=true) => {
        await this.setStateAsync({
            skip:0,
            search: '',
            search_suggestions: [],
            flags: [],
            labels: [],
            containers: [],
            persons: [],
            emails: [],
            recent: false,
            recent_type: '',
            flagged: false,
            labelled: false,
            organized: false,
            unorganized: false,
            shared_by_me: false,
            shared_with_me: false,
            service: [],
            extension: []
        })
        this.fetchFiles(showLoader);
    }

    toggleShowSelectedFiles = async () => {
        if (this.state.file_ids.length > 0) {
            await this.setStateAsync({
                file_ids: []
            })
        } else {
            await this.handleClearSearchAndFilters(false);
            await this.setStateAsync({
                file_ids: this.state.selected.files || []
            })
        }
        await this.fetchFiles();
    }

    handleDeleteFiles = async () => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover these files!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete them!',
            cancelButtonText: 'No, keep them',
            confirmButtonColor: 'red',
        }).then(async (result) => {

            if (!result.isConfirmed) {
                return;
            }

            let payload = {
                file_ids: this.state.selected.files
            };

            showLoading(true);
    
            let response = await deleteMultipleFiles(payload);
            if (response.error) {
                showLoading(false);
                console.error(response.error);
                alert('Error deleting files');
                return;
            }

            showLoading(false);

            this.fetchFiles();

            this.setState({
                selected: {
                    files: [],
                    total_size: 0
                },
                select_all: false,
                show_selection: false,
                file_ids: []
            });
        });
    }

  render() {
    return (
	  <>
		<Helmet>
		  <title> Files | {config.APP_NAME} </title>
		</Helmet>

        <Grid container spacing={2} mb={1} sx={{p: 0, justifyContent: 'space-between'}}>
		  <Grid item xs={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                <Typography variant="h4">
                    Files
                </Typography>
                <Grid display={'flex'} flexDirection={'row'}>
                    {(this.state.available_storages.map((storage) => {
                        if (storage.type === 'onedrive') {
                            return (
                                <OnedriveStatusCheckAndConnect
                                    storageId={storage._id}
                                    hideUnsyncButton={true}
                                    minimal={true}
                                    callbackUrl={process.env.REACT_APP_ONEDRIVE_DASHBOARD_CALLBACK_URL}
                                />
                            )
                        }
                        if (storage.type === 'dropbox') {
                            return (
                                <DropboxStatusCheckAndConnect
                                    storageId={storage._id}
                                    hideUnsyncButton={true}
                                    minimal={true}
                                    callbackUrl={process.env.REACT_APP_DROPBOX_DASHBOARD_CALLBACK_URL}
                                />
                            )
                        }
                        if (storage.type === 'googledrive') {
                            return (
                                <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLEDRIVE_CLIENT_ID}>
                                    <GoogleDriveStatusCheckAndConnect
                                        storageId={storage._id}
                                        hideUnsyncButton={true}
                                        minimal={true}
                                        callbackUrl={process.env.REACT_APP_GOOGLEDRIVE_DASHBOARD_CALLBACK_URL}
                                    />
                                </GoogleOAuthProvider>
                            )
                        }
                    }))}
                </Grid>
		  </Grid>
		</Grid>

        
        <Card variant='outlined' style={{marginBottom: 2}}>
            <CardContent style={{padding: 10}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Stack direction="row" spacing={2} alignItems="center">
                            <TextField
                                variant="outlined"
                                size='small'
                                label="Search"
                                placeholder="Search for files by container, label, name etc."
                                InputProps={{
                                    endAdornment: (
                                        <SearchIcon />
                                    )
                                }}
                                onChange={this.handleSearch}
                                value={this.state.search}
                                sx={{width: '100%'}}
                                autoComplete='off'
                            />
                            
                            <IconButton aria-label="clear" size="large"
                                onClick={async () => {
                                    await this.handleClearSearchAndFilters();
                                }}
                            >
                                <ClearIcon />
                            </IconButton>
                            <Button
                                variant='contained'
                                color='primary'
                                size='small'
                                onClick={() => {
                                    this.setState({
                                        showAddEditContainerModal: true
                                    });
                                }}
                            >
                                Create Container
                            </Button>
                            <IconButton aria-label="advanced-search" size="large"
                                onClick={async () => {
                                    await this.setStateAsync({
                                        advanced_search: !this.state.advanced_search
                                    })
                                }}
                            >
                                {(this.state.advanced_search) ? <ExpandLessIcon /> :  <ExpandMoreIcon />}
                            </IconButton>
                        </Stack>
                    </Grid>
                    
                    {(this.state.labels.length > 0 || this.state.persons.length > 0 || this.state.emails.length > 0 || this.state.containers.length > 0) ? (
                        <Grid item xs={12}>

                            {this.state.containers.map((container, index) => {
                                return (
                                    <Chip
                                        avatar={<InboxIcon style={{color: 'gray'}} />}
                                        key={"state.containers." + index}
                                        label={container.label}
                                        variant='outlined'
                                        onDelete={async () => {
                                            let containers = this.state.containers;
                                            containers = containers.filter(l => String(l.value) !== String(container.value));
                                            await this.setStateAsync({
                                                skip:0,
                                                containers
                                            });
                                            await this.fetchFiles();
                                        }}
                                        sx={{borderRadius: 1, margin: 1}}
                                    />
                                )
                            })}

                            {this.state.labels.map((label, index) => {
                                return (
                                    <Chip
                                        avatar={<LabelIcon style={{color: 'gray'}} />}
                                        key={"state.labels." + index}
                                        label={label.label}
                                        variant='outlined'
                                        onDelete={async () => {
                                            let labels = this.state.labels;
                                            labels = labels.filter(l => String(l.value) !== String(label.value));
                                            await this.setStateAsync({
                                                skip:0,
                                                labels
                                            });
                                            await this.fetchFiles();
                                        }}
                                        sx={{borderRadius: 1, margin: 1}}
                                    />
                                )
                            })}

                            {this.state.persons.map((person, index) => {
                                return (
                                    <Chip
                                        avatar={<AlternateEmailIcon style={{color: 'gray'}} />}
                                        key={"state.persons." + index}
                                        label={person.label}
                                        variant='outlined'
                                        onDelete={async () => {
                                            let persons = this.state.persons;
                                            persons = persons.filter(p => String(p.value) !== String(person.value));
                                            await this.setStateAsync({
                                                skip:0,
                                                persons
                                            });
                                            await this.fetchFiles();
                                        }}
                                        sx={{borderRadius: 1, margin: 1}}
                                    />
                                )
                            })}

                            {this.state.emails.map((person, index) => {
                                return (
                                    <Chip
                                        key={"state.emails." + index}
                                        avatar={<EmailIcon style={{color: 'gray'}} />}
                                        label={person.label}
                                        variant='outlined'
                                        onDelete={async () => {
                                            let emails = this.state.emails;
                                            emails = emails.filter(p => String(p.value) !== String(person.value));
                                            await this.setStateAsync({
                                                skip:0,
                                                emails
                                            });
                                            await this.fetchFiles();
                                        }}
                                        sx={{borderRadius: 1, margin: 1}}
                                    />
                                )
                            })}
                        </Grid>
                    ) : null}
                    
                    {(this.state.search_suggestions.length > 0) ? (
                        <Grid item xs={12}>
                            {(this.state.search_suggestions.map((option, index) => {
                                if (option.type === 'container') {
                                    return (
                                        <Chip
                                            avatar={<InboxIcon style={{color: 'gray'}} />}
                                            variant='outlined'
                                            label={option.label}
                                            onClick={async () => {
                                                let containers = this.state.containers;
                                                containers.push(option);
                                                // delete duplicates based on value
                                                containers = containers.filter((v,i,a)=>a.findIndex(t=>(t.value === v.value))===i)
                                                await this.setStateAsync({
                                                    skip:0,
                                                    search: '',
                                                    containers: containers
                                                })
                                                await this.fetchFiles();
                                            }}
                                            sx={{borderRadius: 1, cursor: 'pointer', margin: 1}}
                                        />
                                    )
                                } else if (option.type === 'label') {
                                    return (
                                        <Chip
                                            avatar={<LabelIcon style={{color: 'gray'}} />}
                                            variant='outlined'
                                            label={option.label}
                                            onClick={async () => {
                                                let labels = this.state.labels;
                                                labels.push(option);
                                                // delete duplicates based on value
                                                labels = labels.filter((v,i,a)=>a.findIndex(t=>(t.value === v.value))===i)
                                                await this.setStateAsync({
                                                    skip:0,
                                                    search: '',
                                                    labels: labels
                                                })
                                                await this.fetchFiles();
                                            }}
                                            sx={{borderRadius: 1, cursor: 'pointer', margin: 1}}
                                        />
                                    )
                                } else if (option.type === 'container') {
                                    return (
                                        <Chip
                                            avatar={<AllInboxIcon style={{color: 'gray'}} />}
                                            variant='outlined'
                                            label={option.label}
                                            onClick={async () => {
                                                let containers = this.state.containers;
                                                containers.push(option);
                                                // delete duplicates based on value
                                                containers = containers.filter((v,i,a)=>a.findIndex(t=>(t.value === v.value))===i)
                                                await this.setStateAsync({
                                                    skip:0,
                                                    search: '',
                                                    containers: containers
                                                })
                                                await this.fetchFiles();
                                            }}
                                            sx={{borderRadius: 1, cursor: 'pointer', margin: 1}}
                                        />
                                    )
                                } else if (option.type === 'person') {
                                    return (
                                        <Chip
                                            avatar={<AlternateEmailIcon style={{color: 'gray'}} />}
                                            variant='outlined'
                                            label={option.label}
                                            onClick={async () => {
                                                let persons = this.state.persons;
                                                persons.push(option);
                                                // delete duplicates based on value
                                                persons = persons.filter((v,i,a)=>a.findIndex(t=>(t.value === v.value))===i)
                                                await this.setStateAsync({
                                                    skip:0,
                                                    search: '',
                                                    persons: persons
                                                })
                                                await this.fetchFiles();
                                            }}
                                            sx={{borderRadius: 1, cursor: 'pointer', margin: 1}}
                                        />
                                    )
                                } else if (option.type === 'email') {
                                    return (
                                        <Chip
                                            avatar={<EmailIcon style={{color: 'gray'}} />}
                                            variant='outlined'
                                            label={option.label}
                                            onClick={async () => {
                                                let emails = this.state.emails;
                                                emails.push(option);
                                                // delete duplicates based on value
                                                emails = emails.filter((v,i,a)=>a.findIndex(t=>(t.value === v.value))===i)
                                                await this.setStateAsync({
                                                    skip:0,
                                                    search: '',
                                                    emails: emails
                                                })
                                                await this.fetchFiles();
                                            }}
                                            sx={{borderRadius: 1, cursor: 'pointer', margin: 1}}
                                        />
                                    )
                                } else {
                                    return (
                                        <Chip
                                            variant='outlined'
                                            label={option.label}
                                            sx={{borderRadius: 1, cursor: 'pointer', margin: 1}}
                                        />
                                    )
                                }
                            }))}
                        </Grid>
                    ) : null}

                    {(this.state.advanced_search) && (
                        <>

                            <Grid item xs={12}>
                                <Card variant='outlined' sx={{borderRadius: 1}}>
                                    <Stack direction="row" spacing={2}>
                                        <FormGroup sx={{flexDirection: 'row', padding: 1}} >

                                            <Grid style={{display: 'flex', alignItems: 'center', paddingLeft: '10px', paddingRight: '30px'}}>
                                                <FlagOutlinedIcon style={{fontSize: '30px'}} />
                                            </Grid>

                                            {flags.map((flag, index) => {
                                                return (
                                                    <FormControlLabel
                                                        key={"flags."+index}
                                                        control={
                                                            <Checkbox
                                                                size='small'
                                                                checked={this.state.flags.includes(flag.value)}
                                                                sx={{
                                                                    color: flag.color,
                                                                    '&.Mui-checked': {
                                                                        color: flag.color,
                                                                    },
                                                                }}
                                                            />
                                                        }
                                                        onChange={async (e) => {
                                                            if (e.target.checked) {
                                                                await this.setStateAsync({
                                                                    flags: [...this.state.flags, flag.value]
                                                                });
                                                                this.handleFlagsSearch();
                                                            } else {
                                                                await this.setStateAsync({
                                                                    flags: this.state.flags.filter(f => f !== flag.value)
                                                                });
                                                                this.handleFlagsSearch();
                                                            }
                                                        }}
                                                        label={flag.label}
                                                        labelPlacement='start'
                                                    />
                                                )
                                            })}
                                        </FormGroup>
                                        <FormGroup sx={{flexDirection: 'row', padding: 1}} >

                                            <Grid style={{display: 'flex', alignItems: 'center', paddingLeft: '15px', paddingRight: '30px'}}>
                                                <ShareIcon style={{fontSize: '30px'}} />
                                            </Grid>

                                            <FormControlLabel
                                                control={<Checkbox size='small' />}
                                                label="Shared by me"
                                                checked={this.state.shared_by_me}
                                                onChange={this.handleSharedByMe}
                                                labelPlacement='start'
                                            />
                                            <FormControlLabel
                                                control={<Checkbox size='small' />}
                                                label="Shared with me"
                                                checked={this.state.shared_with_me}
                                                onChange={this.handleSharedWithMe}
                                                labelPlacement='start'
                                            />
                                        </FormGroup>
                                    </Stack>
                                </Card>
                            </Grid>

                            <Grid item xs={12}>
                                <Card variant='outlined' sx={{borderRadius: 1}} elevation={0}>
                                    <Stack direction="row" spacing={2}>
                                        <Grid style={{display: 'flex', alignItems: 'center', paddingLeft: '15px', paddingRight: '15px'}}>
                                            <FilterAltOutlinedIcon style={{fontSize: '30px'}} />
                                        </Grid>
                                        <FormGroup sx={{flexDirection: 'row', padding: 1}}>
                                            <FormControlLabel
                                                control={<Checkbox size='small' />}
                                                label="Flagged"
                                                checked={this.state.flagged}
                                                onChange={this.handleFlagged}
                                                labelPlacement='start'
                                            />
                                            <FormControlLabel
                                                control={<Checkbox size='small' />}
                                                label="Labelled"
                                                checked={this.state.labelled}
                                                onChange={this.handleLabelled}
                                                labelPlacement='start'
                                            />
                                            <FormControlLabel
                                                control={<Checkbox size='small' />}
                                                label="Organized"
                                                checked={this.state.organized}
                                                onChange={this.handleOrganized}
                                                labelPlacement='start'
                                            />
                                            <FormControlLabel
                                                control={<Checkbox size='small' />}
                                                label="Unorganized"
                                                checked={this.state.unorganized}
                                                onChange={this.handleUnorganized}
                                                labelPlacement='start'
                                            />
                                        </FormGroup>
                                        <FormGroup sx={{flexDirection: 'row', padding: 1}}>
                                            <FormControlLabel
                                                control={<Checkbox size='small' />}
                                                label={<img src={fileIcon.pdf} style={{width: '25px', height: 'auto'}} />}
                                                checked={this.state.extension.includes('pdf')}
                                                onChange={this.handleExtension('pdf')}
                                                labelPlacement='start'
                                            />
                                            <FormControlLabel
                                                control={<Checkbox size='small' />}
                                                label={<img src={fileIcon.word} style={{width: '25px', height: 'auto'}} />}
                                                checked={this.state.extension.includes('word')}
                                                onChange={this.handleExtension('word')}
                                                labelPlacement='start'
                                            />
                                            <FormControlLabel
                                                control={<Checkbox size='small' />}
                                                label={<img src={fileIcon.excel} style={{width: '25px', height: 'auto'}} />}
                                                checked={this.state.extension.includes('excel')}
                                                onChange={this.handleExtension('excel')}
                                                labelPlacement='start'
                                            />
                                            <FormControlLabel
                                                control={<Checkbox size='small' />}
                                                label={<img src={fileIcon.powerpoint} style={{width: '25px', height: 'auto'}} />}
                                                checked={this.state.extension.includes('powerpoint')}
                                                onChange={this.handleExtension('powerpoint')}
                                                labelPlacement='start'
                                            />
                                            <FormControlLabel
                                                control={<Checkbox size='small' />}
                                                label={<img src={fileIcon.image} style={{width: '25px', height: 'auto'}} />}
                                                checked={this.state.extension.includes('image')}
                                                onChange={this.handleExtension('image')}
                                                labelPlacement='start'
                                            />
                                            <FormControlLabel
                                                control={<Checkbox size='small' />}
                                                label={<img src={fileIcon.video} style={{width: '25px', height: 'auto'}} />}
                                                checked={this.state.extension.includes('video')}
                                                onChange={this.handleExtension('video')}
                                                labelPlacement='start'
                                            />
                                        </FormGroup>
                                        <FormGroup sx={{flexDirection: 'row', padding: 1}}>
                                            <FormControlLabel
                                                control={<Checkbox />}
                                                label={<img src={OnedriveLogo} style={{width: '25px', height: 'auto'}} />}
                                                checked={this.state.service.includes(config.FILE_SERVICES.ONEDRIVE)}
                                                onChange={this.handleService(config.FILE_SERVICES.ONEDRIVE)}
                                                labelPlacement='start'
                                            />
                                            <FormControlLabel
                                                control={<Checkbox size='small' />}
                                                label={<img src={DropboxLogo} style={{width: '25px', height: 'auto'}} />}
                                                checked={this.state.service.includes(config.FILE_SERVICES.DROPBOX)}
                                                onChange={this.handleService(config.FILE_SERVICES.DROPBOX)}
                                                labelPlacement='start'
                                            />
                                            <FormControlLabel
                                                control={<Checkbox size='small' />}
                                                label={<img src={GoogleDriveLogo} style={{width: '25px', height: 'auto'}} />}
                                                checked={this.state.service.includes(config.FILE_SERVICES.GOOGLEDRIVE)}
                                                onChange={this.handleService(config.FILE_SERVICES.GOOGLEDRIVE)}
                                                labelPlacement='start'
                                            />
                                        </FormGroup>
                                    </Stack>
                                </Card>
                            </Grid>

                            <Grid item xs={12}>
                                <Card variant='outlined' sx={{borderRadius: 1}} elevation={0}>
                                    <Stack direction="row" spacing={2}>
                                        <Grid style={{display: 'flex', alignItems: 'center', paddingLeft: '15px', paddingRight: '15px'}}>
                                            <SwapVertIcon style={{fontSize: '30px'}} />
                                        </Grid>
                                        <FormGroup sx={{flexDirection: 'row', padding: 1}}>
                                            <Stack spacing={2} sx={{ alignItems: 'center' }}>
                                                <ToggleButtonGroup size="small" aria-label="Age sort"
                                                    value={this.state.age_sort}
                                                    onChange={this.handleAgeSort}
                                                    exclusive={true}
                                                >
                                                    <ToggleButton value="youngest_active" key="youngest_active">
                                                        Youngest Active
                                                    </ToggleButton>
                                                    <ToggleButton value="oldest_active" key="oldest_active">
                                                        Oldest Active
                                                    </ToggleButton>
                                                    <ToggleButton value="youngest_inactive" key="youngest_inactive">
                                                        Youngest Inactive
                                                    </ToggleButton>
                                                    <ToggleButton value="oldest_inactive" key="oldest_inactive">
                                                        Oldest Inactive
                                                    </ToggleButton>
                                                </ToggleButtonGroup>
                                            </Stack>
                                        </FormGroup>
                                        <FormGroup sx={{flexDirection: 'row', padding: 1}}>
                                            <FormControlLabel
                                                control={<Checkbox size='small' />}
                                                label="Recent"
                                                checked={this.state.recent}
                                                onChange={this.handleRecent}
                                            />
                                            {(this.state.recent) && (
                                                <>
                                                    <FormControlLabel
                                                        control={<Checkbox size='small' />}
                                                        label="1 day"
                                                        checked={this.state.recent_type == 'last_day'}
                                                        onChange={this.handleRecentType('last_day')}
                                                    />
                                                    <FormControlLabel
                                                        control={<Checkbox size='small' />}
                                                        label="1 week"
                                                        checked={this.state.recent_type == 'last_week'}
                                                        onChange={this.handleRecentType('last_week')}
                                                    />
                                                    <FormControlLabel
                                                        control={<Checkbox size='small' />}
                                                        label="1 month"
                                                        checked={this.state.recent_type == 'last_month'}
                                                        onChange={this.handleRecentType('last_month')}
                                                    />
                                                </>
                                            )}
                                        </FormGroup>
                                    </Stack>
                                </Card>
                            </Grid>
                        </>
                    )}
                </Grid>
            
            </CardContent>
        </Card>

        <Grid container>
            <Grid container>
                <Grid item xs={12} sx={{minHeight: '80vh', overflow: 'auto'}}>
                    <Card sx={{height: '100%'}} variant='outlined'>
                        <CardContent
                            style={{padding: 10}}
                        >
                            {(this.state.total_files > 0 || this.state?.selected?.files?.length) ? (
                                <Stack direction="row" spacing={2} justifyContent={"space-between"} sx={{mb: 2}}>
                                    {(this.state?.selected?.files?.length > 0) ? (
                                        <Chip
                                            label={`Selected Files: ${this.state.selected.files.length} | Size: ${fData(this.state.selected.total_size)}`}
                                            onDelete={async () => {
                                                await this.setStateAsync({
                                                    selected: {
                                                        files: [],
                                                        total_size: 0
                                                    },
                                                    select_all: false,
                                                    show_selection: false,
                                                    file_ids: []
                                                });
                                            }}
                                        />

                                    ) : <Grid></Grid>}
                                    {(this.state.total_files > 0) ? (
                                        <Chip
                                            label={`Total Files: ${this.state.total_files} | Total Size: ${fData(this.state.total_size)}`}
                                        />
                                    ) : <Grid></Grid>}
                                </Stack>
                            ) : null}

                            {/* Bulk Actions */}
                            {(this.state?.selected?.files?.length) ? (
                                <Stack direction="row" spacing={2} sx={{mb: 2}}>
                                    <Chip
                                        label="Show Selected"
                                        icon={<VisibilityIcon />}
                                        variant={this.state.file_ids?.length ? 'filled' : 'outlined'}
                                        onClick={async () => {
                                            await this.toggleShowSelectedFiles();
                                        }}
                                        color='primary'
                                        disabled={this.state.select_all}
                                    />
                                    <Chip
                                        label="Select All"
                                        icon={<DoneAllIcon />}
                                        variant={this.state.select_all ? 'filled' : 'outlined'}
                                        onClick={async () => {
                                            this.setState({
                                                select_all: !this.state.select_all
                                            });
                                        }}
                                        color='primary'
                                        disabled={this.state.file_ids?.length}
                                    />
                                    <Chip
                                        label="Add labels"
                                        icon={<AddIcon />}
                                        variant='outlined'
                                        onClick={async () => {
                                            this.setState({addLabelModal: true})
                                        }}
                                    />
                                    <Chip
                                        label="Add flags"
                                        icon={<AddIcon />}
                                        variant='outlined'
                                        onClick={async () => {
                                            this.setState({addFlagModal: true})
                                        }}
                                    />
                                    <Chip
                                        label="Add containers"
                                        icon={<AddIcon />}
                                        variant='outlined'
                                        onClick={async () => {
                                            this.setState({addContainerModal: true})
                                        }}
                                    />
                                    <Chip
                                        label="Delete"
                                        icon={<DeleteForeverIcon />}
                                        variant='outlined'
                                        color='error'
                                        onClick={async () => {
                                            this.handleDeleteFiles();
                                        }}
                                    />
                                </Stack>
                            ) : null}

                            <Grid container spacing={1}>
                                {this.state.files.map((file) => {
                                    return (
                                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={file._id}>
                                            <FileCard
                                                key={file._id}
                                                file={file}
                                                showSelection={this.state.show_selection}
                                                selected={this.state.selected.files.includes(file._id) || this.state.select_all}
                                                onSelect={async (file) => {
                                                    let selected = this.state.selected;
                                                    if (selected.files.includes(file._id)) {
                                                        selected.files = selected.files.filter(f => f !== file._id);
                                                        selected.total_size -= file.size;
                                                    } else {
                                                        selected.files.push(file._id);
                                                        selected.total_size += file.size;
                                                    }
                                                    await this.setStateAsync({
                                                        selected,
                                                        show_selection: true
                                                    });
                                                }}
                                                onReload={async () => {
                                                    await this.fetchFiles();
                                                }}
                                            />
                                        </Grid>
                                    );
                                })}
                            </Grid>

                            <Stack spacing={2} sx={{ alignItems: 'center' }}>
                                <Pagination
                                    page={(this.state.skip / this.state.limit) + 1}
                                    count={Math.ceil(this.state.total_files / this.state.limit)}
                                    shape="rounded"
                                    size="large"
                                    onChange={async (event, page) => {
                                        await this.setState({
                                            skip: (page - 1) * this.state.limit
                                        });
                                        await this.fetchFiles();
                                    }}
                                    showFirstButton={true}
                                    showLastButton={true}
                                    boundaryCount={2}
                                    style={{ margin: 20 }}
                                />
                            </Stack>

                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Grid>

        {(this.state.addLabelModal) ? (
            <AddLabelOrFlagModal
                open={this.state.addLabelModal}
                mode={'label'}
                select_all={this.state.select_all}
                query={this.getQuery(true)}
                total_files={this.state.total_files}
                file_ids={this.state.selected.files}
                onClose={() => this.setState({addLabelModal: false})}
                onSave={() => {
                    this.setState({addLabelModal: false})
                    this.fetchFiles();
                }}
            />
        ) : null}
        
        {(this.state.addFlagModal) ? (
            <AddLabelOrFlagModal
                open={this.state.addFlagModal}
                mode={'flag'}
                select_all={this.state.select_all}
                query={this.getQuery(true)}
                total_files={this.state.total_files}
                file_ids={this.state.selected.files}
                onClose={() => this.setState({addFlagModal: false})}
                onSave={() => {
                    this.setState({addFlagModal: false})
                    this.fetchFiles();
                }}
            />
        ) : null}
        
        {(this.state.addContainerModal) ? (
            <AddContainerModal
                open={this.state.addContainerModal}
                select_all={this.state.select_all}
                query={this.getQuery(true)}
                total_files={this.state.total_files}
                file_ids={this.state.selected.files}
                onClose={() => this.setState({addContainerModal: false})}
                onSave={() => {
                    this.setState({addContainerModal: false})
                    this.fetchFiles();
                }}
            />
        ) : null}


        {(this.state.showAddEditContainerModal) ? (
            <AddEditContainerModal
                open={this.state.showAddEditContainerModal}
                onClose={() => {
                    this.setState({
                        showAddEditContainerModal: false
                    });
                }}
                container={null}
                onSave={async () => {
                    await this.setStateAsync({
                        showAddEditContainerModal: false
                    });
                }}
            />
        ) : null}

	  </>
	);
  }
}

// Wrap and export
export default function (props) {
    const navigate = useNavigate();
    const params = useParams();

    return <Files {...props} navigate={navigate} params={params} />;
}
