import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import Swal from "sweetalert2";

import { Grid, Stack, TextField, Typography } from "@mui/material";
import { LoadingButton } from '@mui/lab';

import config from "../config";
import { createFile, updateFile, getFile } from '../services/file.service';
import showLoading from "src/common/loading";


class AddEditFile extends React.Component {

	constructor(props) {
		super(props);
		this.state = {

			mode: props?.params?.file_id ? 'edit' : 'add',
			file_id: props.params?.file_id,

			name: '',
			tags: '',
			labels: '',

			loading: false,
			error: {},
		}
	}

	// promised setState
	setStateAsync(state) {
		return new Promise((resolve) => {
			this.setState(state, resolve)
		});
	}

	async componentDidMount() {
		if (this.state.mode === 'edit') {
			try {

				showLoading(true);

				let file = await getFile(this.state.file_id)

				if (file.error) {

					showLoading(false);

					Swal.fire({
						icon: 'error',
						title: 'Error',
						text: file.error,
						confirmButtonText: 'Ok',
						confirmButtonColor: config.primaryColor
					});
					return;
				}


				file = file.file
				this.setState({
					name: file.name,
					tags: file?.tags?.join(',') ? file.tags.join(',') : '',
					labels: file?.labels?.join(',') ? file.labels.join(',') : ''
				})
				showLoading(false);

			} catch (error) {
				showLoading(false);
				console.error("Error fetching file details:", error)
			}
		}
	}

	saveFile = async () => {

		showLoading(true);

		let tags = this.state.tags.split(',').map(tag => tag.trim());
		tags = tags.filter(tag => tag !== '');
		let labels = this.state.labels.split(',').map(label => label.trim());
		labels = labels.filter(label => label !== '');

		const payload = {
			name: this.state.name,
			tags: tags,
			labels: labels
		}

		let save_file_response;
		if (this.state.mode === 'edit') {
			save_file_response = await updateFile(this.state.file_id, payload);
		} else {
			save_file_response = await createFile(payload)
		}

		if (save_file_response.error) {

			showLoading(false);

			Swal.fire({
				icon: 'error',
				title: 'Error',
				text: save_file_response.error,
				confirmButtonText: 'Ok',
				confirmButtonColor: config.primaryColor
			});
			this.setState({
				loading: false
			});
			return;
		}

		showLoading(false);

		Swal.fire({
			icon: 'success',
			title: 'Success',
			text: 'Saved successfully',
			confirmButtonText: 'Ok',
			confirmButtonColor: config.primaryColor
		}).then(() => {
			this.props.navigate('/files/view');
		})
	}

	render() {
		return (
			<>
				<Helmet>
					<title>{this.state.mode === 'add' ? 'Add_file' : 'Edit File'} | {config.APP_NAME}</title>
				</Helmet>

				<Grid container spacing={2} mb={2} sx={{ p: 1 }}>
					<Grid item>
						<Typography variant="h4">
							{this.state.mode === 'add' ? 'Add file' : 'Edit file'}
						</Typography>
					</Grid>
				</Grid>


				<Grid container spacing={2} mb={2} sx={{ p: 1 }}>
					<Grid item xs={12}>
						<Stack spacing={1}>
							<TextField
								label={'Name'}
								variant="outlined"
								value={this.state.name}
								onChange={async (e) => {
									await this.setState({
										name: e.target.value
									});
								}}
							/>
						</Stack>
					</Grid>
					<Grid item xs={12}>
						<Stack spacing={1}>
							<TextField
								label={'Tags'}
								variant="outlined"
								value={this.state.tags}
								onChange={async (e) => {
									await this.setState({
										tags: e.target.value
									});
								}}
							/>
						</Stack>
					</Grid>
					<Grid item xs={12}>
						<Stack spacing={1}>
							<TextField
								label={'Labels'}
								variant="outlined"
								value={this.state.labels}
								onChange={async (e) => {
									await this.setState({
										labels: e.target.value
									});
								}}
							/>
						</Stack>
					</Grid>
				</Grid>

				<Grid container spacing={2} mb={2} sx={{ p: 1, justifyContent: 'space-around' }}>
					<Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
						<LoadingButton
							disableElevation
							fullWidth
							variant="contained"
							onClick={this.saveFile}
							disabled={this.state.loading}
						>
							{this.state.mode === 'add' ? 'Add file' : 'Save'}
						</LoadingButton>
					</Grid>
				</Grid>
			</>
		);
	}
}


// Wrap and export
export default function (props) {
	const params = useParams();
	const navigate = useNavigate();
	return <AddEditFile {...props} navigate={navigate} params={params} />;
}