import { Autocomplete, Box, Button, Modal, TextField, Typography } from '@mui/material';
import { getUserRefers, referUsers } from 'src/services/user_refer.service';
import Swal from 'sweetalert2';

const React = require('react');

const subject = `Check Out dMonk.ai - an AI-powered platform that simplifies file management`;
const message = `Hey,\n\nJust wanted to share dMonk.ai with you! It's an awesome app that helps you manage documents super easily. You should definitely check it out here - https://dmonk.ai\n\nLet me know what you think! 😄\n\nCheers`;

class UserRefer extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			show_modal: false,
			referred_users: [],
			total_referrals: 3,
			referring: false,
			emails: [],
			subject: subject,
			message: message
		};
	}

	async componentDidMount() {
		await this.updateReferredUsers();
	}

	isReferable = () => {
		// if no emails are entered
		if (this.state.emails.length === 0) {
			return false;
		}

		// if emails are greater than total referrals - referred users
		if (this.state.emails.length > (this.state.total_referrals - this.state.referred_users.length)) {
			return false;
		}

		return true;
	}

	updateReferredUsers = async () => {
		let referred_users = await getUserRefers();
		this.setState({ referred_users: (referred_users?.user_refers ?? []) });
	}

	referUsers = async () => {

		this.setState({ referring: true });

		let payload = {
			emails: this.state.emails,
			subject: this.state.subject,
			message: this.state.message
		};

		// Call API to refer users
		await referUsers(payload);
		await this.updateReferredUsers();

		Swal.fire({
			icon: 'success',
			title: 'Success',
			timer: 3000,
			showConfirmButton: false,
		});

		this.setState({
			show_modal: false,
			referring: false,
			emails: [],
			subject: subject,
			message: message
		});
	}
		


	render() {
		return (
			<>
				<Button
					variant="outlined"
					size="small"
					sx={{ mb: 2 }}
					fullWidth
					onClick={() => this.setState({ show_modal: true })}
				>
					Refer a Friend
				</Button>


				<Modal
					open={this.state.show_modal}
					onClose={() => this.setState({ show_modal: false })}
					aria-labelledby="modal-modal-subject"
					aria-describedby="modal-modal-description"
				>
					<Box sx={modal_style}>
						<Typography id="modal-modal-subject" variant="h6" component="h2">
							You can refer upto {this.state.total_referrals} friends
						</Typography>
						{/* Remaining */}
						<Typography component={'p'} fontSize={12} sx={{ color: 'gray' }}>
							Remaining: {(this.state.total_referrals - this.state.referred_users.length) > 0 ? (this.state.total_referrals - this.state.referred_users.length) : 0}
						</Typography>
						
						{((this.state.total_referrals - this.state.referred_users.length) > 0) ? (
							<>
								<Autocomplete
									freeSolo
									multiple
									id="emails-outlined"
									options={this.state.emails}
									value={this.state.emails}
									onChange={(event, newValue) => {
										this.setState({
											emails: newValue
										});
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											variant="outlined"
											label="Email IDs"
											placeholder={"Email IDs"}
											InputProps={{
												...params.InputProps,
											}}
										/>
									)}
									sx={{ width: '100%', mt: 2 }}
									size="small"
								/>

								<TextField
									size='small'
									id="subject"
									label="Subject"
									variant="outlined"
									sx={{ width: '100%', mt: 2 }}
									value={this.state.subject}
									onChange={(e) => this.setState({ subject: e.target.value })}
								/>

								<TextField
									size='small'
									id="message"
									multiline
									rows={4}
									label="Message"
									variant="outlined"
									sx={{ width: '100%', mt: 2 }}
									value={this.state.message}
									onChange={(e) => this.setState({ message: e.target.value })}
								/>

								<Button
									variant="contained"
									size="small"
									sx={{ mt: 2 }}
									fullWidth
									onClick={this.referUsers}
									disabled={(!this.isReferable()) || this.state.referring}
								>
									{this.state.referring ? 'Referring...' : 'Refer'}
								</Button>
							</>
						) : (
							<>
								<Typography component={'h2'} sx={{ color: 'gray' }} textAlign={'center'}>
									You have already referred {this.state.referred_users.length} friends
								</Typography>
							</>
						)}
					</Box>
				</Modal>
			</>
		);
	}
}

const modal_style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '60%',
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
  };

export default UserRefer;
