import axios from './axios.service';
import config from './../config';
import { getQueryStringFromObject, getRequestHeaders } from './common.service';

async function getContainers(query=null) {
  try {
    let queryString = '';
    if (query) {
        queryString = '?' + getQueryStringFromObject(query);
    }
    let response;
    try {
        let headers = getRequestHeaders();
        response = await axios.get( config.SERVER_BASE_URL + '/containers' + queryString, { headers: headers });
    } catch (error) {
        return error.response.data;
    }
    return response.data;
  } catch (error) {
      return { error: error.message };
  }
}

async function getContainer(containerId) {
    let response;
    try {
        let headers = getRequestHeaders();
        response = await axios.get( config.SERVER_BASE_URL + '/containers/' + containerId, { headers: headers });
    } catch (error) {
        return error.response.data;
    }
    return response.data;
}

async function updateContainer(containerId, payload) {
  let response;
  try {
      let headers = getRequestHeaders();
      response = await axios.patch( config.SERVER_BASE_URL + '/containers/' + containerId, payload, { headers: headers });
  } catch (error) {
      return error.response.data;
  }
  return response.data;
}

async function addContainer(payload) {
  let response;
  try {
      let headers = getRequestHeaders();
      response = await axios.post( config.SERVER_BASE_URL + '/containers', payload, { headers: headers });
  } catch (error) {
      return error.response.data;
  }
  return response.data;
}

async function deleteContainer(containerId) {
  let response;
  try {
      let headers = getRequestHeaders();
      response = await axios.delete( config.SERVER_BASE_URL + '/containers/' + containerId, { headers: headers });
  } catch (error) {
      return error.response.data;
  }
  return response.data;
}

export {
    getContainers,
    getContainer,
    updateContainer,
    addContainer,
    deleteContainer
}
