import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Button, Card, CardHeader, Grid, IconButton, Pagination, Stack, TextField, Typography } from '@mui/material';
import config from 'src/config';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import showLoading from 'src/common/loading';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { deleteContainer, getContainers } from 'src/services/container.service';
import AddEditContainerModal from './AddEditContainer.modal';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

// ----------------------------------------------------------------------



class ContainersPage extends React.Component {

	constructor(props) {
		super(props);

		this.state = {

			role: localStorage.getItem('role') || config.USER_ROLES.USER,

			showAddEditContainerModal: false,
			selectedContainer: null,

			containers: [],
			search: '',
			limit: 10,
			skip: 0,
			total_containers: 0,
		}
	}

	// promised setState
	setStateAsync(state) {
		return new Promise((resolve) => {
			this.setState(state, resolve)
		});
	}

	async componentDidMount() {
		await this.fetchContainers();
	}

	async fetchContainers(showLoader = true) {
		try {

			if (showLoader)
				showLoading(true);


			const options = {
				limit: this.state.limit,
				skip: this.state.skip
			}

			if (this.state.search)
				options.search = this.state.search;

			const containers = await getContainers(options);
			if (containers.error) {

				showLoading(false);

				Swal.fire({
					icon: 'error',
					title: 'Error',
					text: containers.error,
					confirmButtonText: 'Ok',
					confirmButtonColor: config.primaryColor
				});
				return;
			}

			this.setState({
				containers: containers.containers || [{name: 'Hello'}],
				total_containers: containers.count || 0
			});
			showLoading(false);

		} catch (error) {
			showLoading(false);
			console.error("Error fetching containers:", error.message)
		}
	}


	handleSearch = async (e) => {

		await this.setStateAsync({
			skip: 0,
			search: e.target.value
		})

		await this.fetchContainers(false);
	}

	deleteContainer = async (container) => {

		const confirm = await Swal.fire({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#d33',
			// cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!'
		});

		if (confirm.isConfirmed) {
			console.log("Deleting container:", container);
			// Call the delete API here
			const response = await deleteContainer(container._id);
			if (response.error) {
				Swal.fire({
					icon: 'error',
					title: 'Error',
					text: response.error,
					confirmButtonText: 'Ok',
					confirmButtonColor: config.primaryColor
				});
				return;
			}
			Swal.fire({
				icon: 'success',
				title: 'Deleted!',
				text: 'Container has been deleted.',
				confirmButtonText: 'Ok',
				confirmButtonColor: config.primaryColor
			});
			await this.fetchContainers();
		}

	}

	render() {
		return (
			<>
				<Helmet>
					<title> Containers | {config.APP_NAME} </title>
				</Helmet>

				<Grid container spacing={2} mb={2}>
					<Grid item xs={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
						<Typography variant="h4">
							Containers
						</Typography>
						<Stack direction="row" spacing={2} alignItems="center" width={'50%'} flex={1} paddingLeft={10} paddingRight={10}>
							<TextField
								size='small'
								label={'Search'}
								variant="outlined"
								fullWidth
								value={this.state.search}
								InputProps={{ endAdornment: <SearchIcon /> }}
								onChange={this.handleSearch}
							/>
							<IconButton aria-label="clear" size="large"
								onClick={async () => {
									await this.setStateAsync({
										skip: 0,
										search: ''
									})
									await this.fetchContainers();
								}}
							>
								<ClearIcon />
							</IconButton>
						</Stack>
						<Button
							variant='contained'
							color='primary'
							size='small'
							onClick={() => {
								this.setState({
									showAddEditContainerModal: true,
									selectedContainer: null
								});
							}}
						>
							Create Container
						</Button>
					</Grid>
				</Grid>

				<Grid container spacing={2}>

					{(this.state.containers.map((container) => (
						<Grid item xs={12} sm={6} md={4} lg={3} key={container._id}>
							<Card variant='outlined'>
								<CardHeader
									title={container.name}
									subheader={(container.is_owner) ? (<AdminPanelSettingsIcon />) : (<PeopleAltIcon />)}
									style={{ padding: 10 }}
									action={
										<Stack direction={'row'}>
											<IconButton aria-label="delete"
												onClick={() => {
													this.setState({
														showAddEditContainerModal: true,
														selectedContainer: container
													});
												}}
											>
												<EditIcon />
											</IconButton>
											<IconButton aria-label="delete" color='error'
												onClick={() => {
													this.deleteContainer(container);
												}}
											>
												<DeleteIcon />
											</IconButton>
										</Stack>
									}
								/>
							</Card>
						</Grid>
					)))}
				</Grid>

				<Stack spacing={2} sx={{ alignItems: 'center' }}>
					<Pagination
						page={(this.state.skip / this.state.limit) + 1}
						count={Math.ceil(this.state.total_containers / this.state.limit)}
						shape="rounded"
						size="large"
						onChange={async (event, page) => {
							await this.setState({
								skip: (page - 1) * this.state.limit
							});
							await this.fetchContainers();
						}}
						showFirstButton={true}
						showLastButton={true}
						boundaryCount={2}
						style={{ margin: 20 }}
					/>
				</Stack>


				{(this.state.showAddEditContainerModal) ? (
					<AddEditContainerModal
						open={this.state.showAddEditContainerModal}
						onClose={() => {
							this.setState({
								showAddEditContainerModal: false
							});
						}}
						container={this.state.selectedContainer}
						onSave={async () => {
							await this.setStateAsync({
								showAddEditContainerModal: false
							});
							await this.fetchContainers();
						}}
					/>
				) : null}

			</>
		);
	}
}

export default function (props) {
	const navigate = useNavigate();

	return <ContainersPage {...props} navigate={navigate} />;
}
