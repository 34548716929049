import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import InboxIcon from "@mui/icons-material/Inbox";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";

import Swal from "sweetalert2";

import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Backdrop, Button, Card, Checkbox, Fade, FormControlLabel, FormHelperText, Grid, Modal, Stack, TextField, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import config from "../config";
import { updateContainersToMultipleFiles } from '../services/file.service';
import showLoading from "src/common/loading";
import { addContainer, getContainers } from "src/services/container.service";

const flags = [
	{ value: 'attachment', label: 'Attachment', color: 'purple' },
	{ value: 'important', label: 'Important', color: 'red' },
	{ value: 'in_house', label: 'In house', color: 'blue' },
	{ value: 'temp', label: 'Temp', color: 'black' },
	{ value: 'wip', label: 'WIP', color: 'orange' },
	{ value: 'complete', label: 'Complete', color: 'green' },
	{ value: 'final', label: 'Final', color: 'red'},
	{ value: 'archive', label: 'Archive', color: 'grey'},
]

class AddContainerModal extends React.Component {

	constructor(props) {
		super(props);
		this.state = {

			select_all: props?.select_all ? props.select_all : false,
			query: props?.query ? props.query : {},
			total_files: props?.total_files ? props.total_files : 0,
            file_ids: props?.file_ids ? props.file_ids : [],

			containers: [],
			container_suggestions: [],

			operation: "append", // append, replace
			
			loading: false,
			error: {},
		}
	}

	// promised setState
	setStateAsync(state) {
		return new Promise((resolve) => {
			this.setState(state, resolve)
		});
	}

	async componentDidMount() {
		
	}

	updateContainerSuggestions = async (search=null) => {
		try {

			let query = {
				limit: 10,
				skip: 0
			};
			if (search) {
				query.search = search;
			}

			let containers = await getContainers(query);
			
			if (containers.error) {
				console.log("Error fetching containers:", containers.error);
				this.setState({
					container_suggestions: []
				});
				return;
			}

			containers = containers.containers;

			let container_suggestions = containers.map(container => {
				return {
					id: container._id,
					label: container.name,
					is_owner: container.is_owner
				}
			});

			this.setState({
				container_suggestions: container_suggestions
			});

		} catch (error) {
			console.error("Error fetching container suggestions:", error);
		}
	}

	saveContainer = async () => {

		this.setState({
			loading: true
		});
		showLoading(true);

		let payload = {
			containers: this.state.containers.map(container => container.id),
			operation: this.state.operation
		}

		if (this.state.select_all) {
			payload.select_all = true;
			payload.query = this.state.query;
			payload.total_files = this.state.total_files;
		} else {
			payload.file_ids = this.state.file_ids;
		}

		let save_response = await updateContainersToMultipleFiles(payload);

		if (save_response.error) {

			showLoading(false);

			Swal.fire({
				icon: 'error',
				title: 'Error',
				text: save_response.error,
				confirmButtonText: 'Ok',
				confirmButtonColor: config.primaryColor
			});
			this.setState({
				loading: false
			});
			return;
		}

		showLoading(false);
		this.setState({
			loading: false
		});

		Swal.fire({
			icon: 'success',
			title: 'Success',
			text: 'Saved successfully',
			confirmButtonText: 'Ok',
			confirmButtonColor: config.primaryColor
		});
		this.props.onSave();

	}

	createContainer = async (container_name) => {
		let createdContainer = await addContainer({
			name: container_name
		})
		if (createdContainer.error) {
			return null;
		}
		return createdContainer.container;
	}

	render() {
		return (
			<div>
				<Modal
					aria-labelledby="transition-modal-title"
					aria-describedby="transition-modal-description"
					open={this.props.open}
					onClose={() => {

						if (this.props.onClose)
							this.props.onClose();
					}}
					closeAfterTransition
					slots={{ backdrop: Backdrop }}
					slotProps={{
						backdrop: {
							timeout: 500,
						},
					}}
				>
					<Fade in={this.props.open}>
						<Card sx={modalStyle}>
							<Grid container spacing={2} mb={2} sx={{ p: 1 }}>
								
								<Grid item xs={12}>
									<Stack spacing={1} direction={'row'}>
										<Autocomplete
											freeSolo
											multiple
											id="containers-outlined"
											options={this.state.container_suggestions}
											value={this.state.containers}
											onChange={async (event, newValue) => {
												// Create new container
												let updatedContainers = [];
												for (let i = 0; i < newValue.length; i++) {
													if (newValue[i].create) {
														let created_container = await this.createContainer(newValue[i].id);
														if (!created_container) {
															
														} else {
															updatedContainers.push({
																id: created_container._id,
																label: created_container.name,
																is_owner: created_container.is_owner
															});
														}
													} else if (typeof newValue[i] === 'string') {
														let created_container = await this.createContainer(newValue[i]);
														if (!created_container) {

														} else {
															updatedContainers.push({
																id: created_container._id,
																label: created_container.name,
																is_owner: created_container.is_owner
															});
														}
													} else {
														updatedContainers.push(newValue[i]);
													}
												}


												this.setState({
													containers: updatedContainers
												});
											}}
											onFocus={() => {
												this.updateContainerSuggestions();
											}}
											renderInput={(params) => (
												<TextField
													{...params}
													variant="outlined"
													label="Containers"
													placeholder="What is it ?"
													InputProps={{
														...params.InputProps,
														endAdornment: (
															<React.Fragment>
																{params.InputProps.endAdornment}
																<InboxIcon />
															</React.Fragment>
														)
													}}
													onChange={this.updateContainerSuggestions}
												/>
											)}
											renderOption={(props, option) => (
												<li {...props}>
													<Grid container alignItems="center" spacing={1} justifyContent={'space-between'} textAlign={'center'}>
														<Grid item>
															{option.label}
														</Grid>
														<Grid item justifyContent={'center'} alignItems={'center'}>
															{(option.is_owner) ? (<AdminPanelSettingsIcon />) : (<PeopleAltIcon />)}
														</Grid>
													</Grid>
												</li>
											)}
											sx={{ width: '100%' }}
											size="small"
										/>
									</Stack>
								</Grid>

								<Grid item xs={12}>
									<Accordion disableGutters elevation={3}>
										<AccordionSummary
											expandIcon={<ExpandMoreIcon />}
											aria-controls="panel1-content"
											id="panel1-header"
										>
												<Typography component="span">Advanced Options</Typography>
										</AccordionSummary>
										<AccordionDetails>
											<Stack direction={'row'}>
												<FormControlLabel
													control={
														<Checkbox
															checked={this.state.operation == 'append'}
															onChange={(e) => {
																this.setState({
																	operation: 'append'
																});
															}}
														/>
													}
													label="Append"
												/>
												<FormControlLabel
													control={
														<Checkbox
															checked={this.state.operation == 'replace'}
															onChange={(e) => {
																this.setState({
																	operation: 'replace'
																});
															}}
														/>
													}
													label="Replace"
												/>
											</Stack>
											<FormHelperText>
												{this.state.operation == 'append' ? 'Append new containers to existing ones' : 'Replace existing containers with new ones. Note: Not giving any containers will remove all existing containers.'}
											</FormHelperText>
										</AccordionDetails>
									</Accordion>
								</Grid>

							</Grid>

							<Grid container spacing={2} mb={2} sx={{ p: 1, justifyContent: 'space-around' }}>
								<Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
									<Button
										disableElevation
										fullWidth
										variant="contained"
										onClick={this.saveContainer}
										disabled={this.state.loading}
										size="small"
									>
										{this.state.loading ? 'Saving...' : 'Save'}
									</Button>
								</Grid>
							</Grid>


						</Card>
					</Fade>
				</Modal>
			</div>
		);
	}
}

const modalStyle = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '80%',
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 2,
	maxHeight: '90%',
	overflowY: 'auto'
};

// Wrap and export
export default function (props) {
	const params = useParams();
	const navigate = useNavigate();
	return <AddContainerModal {...props} navigate={navigate} params={params} />;
}