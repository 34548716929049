import axios from './axios.service';
import config from './../config';
import { getQueryStringFromObject, getRequestHeaders } from './common.service';

async function getUserRefers(query=null) {
  try {
    let queryString = '';
    if (query) {
        queryString = '?' + getQueryStringFromObject(query);
    }
    let response;
    try {
        let headers = getRequestHeaders();
        response = await axios.get( config.SERVER_BASE_URL + '/user_refers' + queryString, { headers: headers });
    } catch (error) {
        return error.response.data;
    }
    return response.data;
  } catch (error) {
      return { error: error.message };
  }
}

async function referUsers(payload) {
    let response;
    try {
        let headers = getRequestHeaders();
        response = await axios.post( config.SERVER_BASE_URL + '/user_refers', payload, { headers: headers });
    } catch (error) {
        return error.response.data;
    }
    return response.data;
}

export {
    getUserRefers,
    referUsers,
}
